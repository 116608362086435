import onBreakpoint from '../utils/on-breakpoint';

const setStyles = (css, elem) => {
  // eslint-disable-next-line no-param-reassign
  elem.style.cssText = css;
};

const EventHandler = (() => {
  class EventClass {
    constructor() {
      this.functionMap = {};
    }

    addEventListener(event, func) {
      this.functionMap[event] = func;
      document.addEventListener(event.split('.')[0], this.functionMap[event]);
    }

    removeEventListener(event) {
      document.removeEventListener(
        event.split('.')[0],
        this.functionMap[event],
      );
      delete this.functionMap[event];
    }
  }

  return EventClass;
})();

const Event = new EventHandler();

const generateStyle = (imageUrl, fallbackColor) => {
  if (!imageUrl) {
    return '';
  }

  return `background-image: url(${imageUrl});${
    fallbackColor ? `background-color:${fallbackColor};` : ''
  }`;
};

const getClickHandler =
  (url, elem, checkIsValid) =>
  ({ target }) => {
    let open = false;
    if (target === window.document.body) {
      open = true;
    }

    if (target === elem) {
      open = true;
    }

    if (checkIsValid) {
      const isValid = checkIsValid(target);
      if (isValid) {
        open = true;
      }
    }

    if (open) {
      window.open(url);
    }
  };

const setupClickAndResetListeners = (
  url,
  elem,
  checkIsValid,
  additionalReset,
  clear = true,
) => {
  if (clear) {
    // We will unbind any existing click events
    Event.removeEventListener('click.ad');
  }

  // And bind a click-handler to open the desired destination when
  // clicked
  const clickHandler = getClickHandler(url, elem, checkIsValid);
  Event.addEventListener('click.ad', clickHandler);

  // We want to know when the breakpoint changes, so we can undo all of our
  // setup
  const breakpointListener = onBreakpoint(
    {
      base: () => {
        // Remove the current wallpaper style
        setStyles('', elem);
        // Remove the click-handler
        Event.removeEventListener('click.ad');
        // And remove this breakpoint-listener
        onBreakpoint.unbind(breakpointListener);
        // If we have any additional reset functions, also call those
        if (additionalReset) {
          additionalReset();
        }
      },
    },
    true,
  );
};

export default {
  // initiator
  create: () => {
    // Create ad-element to contain the creative
    const elem = document.createElement('div');
    elem.classList.add('tc_ad__object');
    elem.setAttribute('aria-hidden', true);
    elem.setAttribute('tabindex', '-1');

    return elem;
  },

  dd: (
    {
      wallpaper_url: wallpaperUrl,
      click_url: clickUrl,
      fallback_color: fallbackColor = null,
    },
    elem,
  ) => {
    const adElem = document.querySelector('.tc_ad--dd');
    if (!adElem) {
      return;
    }

    // Inject the style
    setStyles(generateStyle(wallpaperUrl, fallbackColor), elem);

    // Inject the ad
    adElem.appendChild(elem);

    // Set body data-ad to dd
    window.document.body.setAttribute('data-ad', 'dd');

    setupClickAndResetListeners(
      clickUrl,
      elem,
      (target) => {
        const hasDDClassName = (node) => {
          return node.classList.contains('tc_ad--dd');
        };

        if (hasDDClassName(target)) {
          return true;
        }

        return hasDDClassName(target.parentNode);
      },
      () => {
        window.document.body.removeAttribute('data-ad');
      },
    );
  },

  // interscroll banner
  interscroll: ({ img_url: imageUrl, click_url: clickUrl }, elem) => {
    const adElems = document.querySelectorAll(
      '[data-interscroll-position="true"]',
    );
    if (!adElems.length > 0) {
      return;
    }

    // Inject the style
    setStyles(generateStyle(imageUrl), elem);

    adElems.forEach((adElem) => {
      const adObject = elem.cloneNode(true);

      // Inject the ad
      adElem.appendChild(adObject);
    });

    // Set body data-ad to interscroll
    window.document.body.setAttribute('data-ad', 'interscroll');

    setupClickAndResetListeners(
      clickUrl,
      elem,
      (target) => {
        const hasInterscrollAttribute = (node) => {
          return node.getAttribute('data-interscroll-position') === 'true';
        };

        if (hasInterscrollAttribute(target)) {
          return true;
        }

        return hasInterscrollAttribute(target.parentNode);
      },
      () => {
        window.document.body.removeAttribute('data-ad');
      },
    );
  },

  // midscroll banner
  midscroll: ({ img_url: imgUrl, click_url: clickUrl, elementId }, elem) => {
    const adElem = document.getElementById(elementId);
    if (!adElem) {
      return;
    }

    // Tag the ad with a class
    adElem.classList.add('tc_ad--midscroll');

    // Inject the style
    setStyles(generateStyle(imgUrl), elem);

    // Inject the ad
    adElem.appendChild(elem);

    setupClickAndResetListeners(clickUrl, elem);
  },
};
