import { getBreakpoint } from './utils/getBreakpoint';
import { pbjsHandleSync } from './utils/pbjsHandleSync';
import { getMsupplyKeywords } from './utils/getMsupplyKeywords';
import { adPath } from './utils/adPath';

const log = window.tv2.utils.setupLog('⛸️ RelevantDigital');

/* Settings per site (placements, etc) in Yield */
const CONFIG_IDS = {
  desktop: {
    frontpage: '62751b1f71fc1390cf573097', // tv2.dk Frontpage (Desktop/Tablet)
    other: '62751b20d0b3a9ecfe573098', // tv2.dk Other (Desktop/Tablet)
  },
  mobile: {
    frontpage: '62751b2152c135e71d573099', // tv2.dk Frontpage (Mobile)
    other: '62751b222b00598ee557309b', // tv2.dk Other (Mobile)
  },
};

/* Instream ad types (prerolls/bumpers), which are not added via DOM elements */
const INSTREAM_TYPES = ['Video_1', 'Video_Bumpers'];

/* 'mobile' vs 'desktop' */
const getDeviceType = () => {
  return ['base', 'small'].indexOf(getBreakpoint()) >= 0 ? 'mobile' : 'desktop';
};

/* 'frontpage' vs 'other' */
const getPageType = () => {
  let pageType = 'frontpage';
  const pfx = window.tv2?.ads?.adPositionPrefix;
  if (pfx) {
    if (pfx.toLowerCase().indexOf('forside') < 0) {
      pageType = 'other';
    }
  } else {
    log.error('Failed getting adPostionPrefix');
  }
  return pageType;
};

/* Check if placement should be loaded with current 'deviceType' (desktop vs mobile) */
function adElementBreakpointValid(elm) {
  let limitTo;
  const deviceType = getDeviceType();
  const isNone = (attr) => ['none', ''].indexOf(elm.getAttribute(attr)) >= 0;
  if (isNone('data-sizes') || isNone('data-sizes-small')) {
    limitTo = 'desktop';
  } else if (isNone('data-sizes-medium')) {
    limitTo = 'mobile';
  }
  return !limitTo || deviceType === limitTo;
}

function pathFromType(type) {
  return `/${adPath(
    type,
    window.tv2?.adPathPrefix,
    window.tv2?.adPositionPrefix,
  ).join('/')}`;
}

function getConfig() {
  const pageType = getPageType();
  const deviceType = getDeviceType();
  const configId = CONFIG_IDS[deviceType][pageType];
  return window.relevantDigital
    .getConfigs()
    .find((cfg) => cfg.configId === configId);
}

function getAdTags() {
  const slots = [];
  const divs = [].slice.call(
    document.querySelectorAll('div[data-ad]:not([data-ad-ignore])'),
  );

  divs.forEach((div) => {
    const type = div.getAttribute('data-ad');
    if (!type) {
      return;
    }

    const isValidBreakpoint = adElementBreakpointValid(div);
    if (!isValidBreakpoint) {
      return;
    }

    const path = pathFromType(type);
    slots.push(path);
    div.setAttribute('data-ad-unit-id', path);
    div.setAttribute('id', div.id || type);
  });

  return slots;
}

// Configure user sync with advertisement ID
function addUserSync(advertisementId) {
  const { relevantDigital } = window;
  relevantDigital.addPrebidConfig({
    userSync: {
      userIds: [
        {
          name: 'pubProvidedId',
          params: {
            eids: [
              {
                source: 'tv2.dk',
                uids: [
                  {
                    id: advertisementId,
                    atype: 1,
                  },
                ],
              },
            ],
          },
        },
      ],
    },
  });
}

// Add tv2Context object from tracking params
function addTv2Context(tracking) {
  const { relevantDigital } = window;
  const { site, section, sport, tema, event, tv2context = [] } = tracking;
  const tv2ContextValues = [site, section, sport, tema, event, ...tv2context]
    .filter(Boolean)
    .map((entry) => String(entry).trim());

  relevantDigital.addPrebidConfig({
    ortb2: {
      user: {
        keywords: 'bidder',
        ext: {
          data: {
            registered: true,
            tv2context: tv2ContextValues,
          },
        },
      },
    },
  });
}

/* Will be called by the Relevant code after an 'ad slot' has been matched to a placement in Yield.
 * Because of the 'delayedAdserverLoading' parameter to relevantDigital.loadPrebid() this will not be
 * a googletag.Slot instance but an internal object with a minimal but slightly similar api */
function handlSlotAndUnit({ slot, unit, advertisementId, tracking }) {
  /* Spcial bid parameter setup for some bidders */
  const byBidder = {
    appnexus: (params) => {
      // eslint-disable-next-line no-param-reassign
      params.user = { externalUid: advertisementId };
    },
    msupply: (params) => {
      byBidder.appnexus(params);
      // eslint-disable-next-line no-param-reassign
      params.keywords = getMsupplyKeywords(tracking);
    },
  };
  /* Make it work also when enabling Prebid Server as '_s2s_alias' will be appended
   * to each bidder name in this case) */
  Object.entries(byBidder).forEach(([bidder, fn]) => {
    byBidder[`${bidder}_s2s_alias`] = fn;
  });

  const { minWidth, twinType, hasTwin } = unit.data;
  if (minWidth && window.innerWidth < minWidth) {
    return false; // screen too narrow for placement (used for skyscrapers), reject placement
  }

  const elm = document.getElementById(slot.getSlotElementId());
  if (!unit.isInstream && (!elm || !adElementBreakpointValid(elm))) {
    return false; // we should not load this placement
  }

  unit.pbAdUnit.bids.forEach(({ bidder, params }) => {
    if (byBidder[bidder]) {
      byBidder[bidder](params); // Setup special bid parameters
    }
  });

  /* For the twin placements we need to notify that this slot can be used simultaneously be another placement
   * For example, both the "Topbanner 1" and "Wallpaper" placements in Yield will use a single slot. */
  return { reUseSlot: twinType || hasTwin };
}

function parseInstreamUnit({ gamPath }) {
  const type = INSTREAM_TYPES.find((ty) =>
    gamPath.toLowerCase().includes(ty.toLowerCase()),
  );
  return {
    path: pathFromType(type),
    id: type,

    // "Hack" to make the current instream-code work as it requires us to set the ad unit code properly
    tv2InstreamType: type,
  };
}

export function requestBids({ tracking, advertisementId }) {
  const { relevantDigital } = window;
  const { configId, adUnits } = getConfig();

  // Load all instream units from the configuration
  const instreamUnits = adUnits.filter((u) => u.isInstream);
  const relevantInstreamUnits = instreamUnits.map(parseInstreamUnit);
  relevantDigital.defineVideoSlots(relevantInstreamUnits);

  relevantDigital.loadPrebid({
    configId,
    noGpt: true,
    manageAdserver: false,
    noAdsInitRequestAll: true, // Avoid requesting all slots in first request
    hasSharedAdUnits: true, // As twin placements are using the same GAM ad unit paths
    noSlotReload: true,
    googletagCalls: {
      refresh(slots) {
        // We'll call googletag.pubads().refresh() in googleAds.js instead
        if (this.auction.isReloadAuction) {
          // To make built-in reload-functionality work
          window.googletag.pubads().refresh(slots);
        }
      },
    },
    // When auction starts
    onBeforeAuctionSetup: ({ auction }) => {
      log.info('Auction starts', auction);
    },
    // When googletag slots and prebid units are ready
    onSlotAndUnit: ({ slot, unit }) =>
      handlSlotAndUnit({ slot, unit, tracking, advertisementId }),

    // Before requesting ads (we will not request ads from here)
    onBeforeAdRequest: ({ auction }) => {
      log.info('Auction ended', auction);
      const pbjs = window.pbjs || {};
      pbjs.auctionEnded = true;
      window.tv2messages.publish('pbjsDone', true);
    },
    // Rewrite prebid ad units into our format
    createAdUnitCode: ({ unit, slot }) =>
      `${slot.getAdUnitPath()}${
        unit.data.twinType ? `--${unit.data.twinType}` : ''
      }`,
  });
}

function initRelevantScript(doneCb) {
  window.tv2.utils.loadScript(
    'https://tv2-cdn.relevant-digital.com/static/tags/6273e82d5f10b4afc72cea07.js',
    () => {
      // pbjs is on page
      const pbjs = window.pbjs || {};
      pbjs.que = pbjs.que || [];
      pbjs.auctionEnded = false;
      pbjs.handleSync = pbjsHandleSync; // Add sync handler

      const relevantDigital = window.relevantDigital || {};
      relevantDigital.cmd = relevantDigital.cmd || [];
      relevantDigital.cmd.push(() => {
        // Relevant is ready
        pbjs.que.push(doneCb);
      });
    },
  );
}

export function loadRelevant(tracking, advertisementId) {
  const slots = getAdTags();

  if (!slots || !slots.length) {
    log.warn('No relevant slots defined, initiation aborted');
    window.tv2messages.publish('pbjsDone', false);
    return;
  }

  log.info('Load script');
  try {
    initRelevantScript(() => {
      addUserSync(advertisementId);
      addTv2Context(tracking);
      requestBids({
        tracking,
        advertisementId,
      });
    });
  } catch (err) {
    log.error('Script failed', err);
    window.tv2messages.publish('pbjsDone', false);
  }
}
